export const calculateCollegeListBalance = (safety, target, reach, total) => {
    if (total === 0) return 'No colleges';
  
    const safetyPercentage = (safety / total) * 100;
    const targetPercentage = (target / total) * 100;
    const reachPercentage = (reach / total) * 100;
  
    const isBalanced = 
      safetyPercentage >= 20 && safetyPercentage <= 30 &&
      targetPercentage >= 40 && targetPercentage <= 50 &&
      reachPercentage >= 20 && reachPercentage <= 30;
  
    return isBalanced ? 'Balanced' : 'Unbalanced';
  };