// src/majorMapping.js

const majorMapping = {
    PCIP01: "Agriculture, Agriculture Operations, and Related Sciences",
    PCIP03: "Natural Resources and Conservation",
    PCIP04: "Architecture and Related Services",
    PCIP05: "Area, Ethnic, Cultural, Gender, and Group Studies",
    PCIP09: "Communication, Journalism, and Related Programs",
    PCIP10: "Communications Technologies/Technicians and Support Services",
    PCIP11: "Computer and Information Sciences and Support Services",
    PCIP12: "Personal and Culinary Services",
    PCIP13: "Education",
    PCIP14: "Engineering",
    PCIP15: "Engineering Technologies and Engineering-Related Fields",
    PCIP16: "Foreign Languages, Literatures, and Linguistics",
    PCIP19: "Family and Consumer Sciences/Human Sciences",
    PCIP22: "Legal Professions and Studies",
    PCIP23: "English Language and Literature/Letters",
    PCIP24: "Liberal Arts and Sciences, General Studies and Humanities",
    PCIP25: "Library Science",
    PCIP26: "Biological and Biomedical Sciences",
    PCIP27: "Mathematics and Statistics",
    PCIP29: "Military Technologies and Applied Sciences",
    PCIP30: "Multi/Interdisciplinary Studies",
    PCIP31: "Parks, Recreation, Leisure, Fitness, and Kinesiology",
    PCIP38: "Philosophy and Religious Studies",
    PCIP39: "Theology and Religious Vocations",
    PCIP40: "Physical Sciences",
    PCIP41: "Science Technologies/Technicians",
    PCIP42: "Psychology",
    PCIP43: "Homeland Security, Law Enforcement, Firefighting, and Related Protective Services",
    PCIP44: "Public Administration and Social Service Professions",
    PCIP45: "Social Sciences",
    PCIP46: "Construction Trades",
    PCIP47: "Mechanic and Repair Technologies/Technicians",
    PCIP48: "Precision Production",
    PCIP49: "Transportation and Materials Moving",
    PCIP50: "Visual and Performing Arts",
    PCIP51: "Health Professions and Related Programs",
    PCIP52: "Business, Management, Marketing, and Related Support Services",
    PCIP54: "History",
    // Add other mappings if needed
};

export default majorMapping;
